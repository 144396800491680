.authPage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;

    form{
        display: inline-block;
        margin: auto;

        fieldset{
            padding: 90px 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid #0057FF;
            border-radius: 7px;

            > *{
                text-align: center;
                width: 300px;
                margin: 10px auto;
            }

            img{
                width: 60px;
                height: 60px;
            }

            h1{
                margin-bottom: 30px;
            }

            a{
                margin-top: 0;
                text-align: left;
                display: inline-block;
                font-size: 14px;
                color: black;

                &:hover{
                    color: #0057FF;
                }
            }
        }
    }
}
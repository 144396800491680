$primary-color: #006cb8;
$secondary-color: #9d9f9e;
$danger-color: #cb3535;
$success-color: #79b833;
$modest-color: #efefef;

$base-color: #ffffff;

$modest-color-font: #c1bcbc;
$modest-color-border: #ebebeb;

$border-radius: 4px;

@import "src/styles/variables";

.stepTwo-container {
    position: relative;

    display: grid;
    grid-template-columns: 2fr 1fr;

    .inputs {
        padding-right: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        >div {
            margin-bottom: 15px;
            cursor: pointer;

            .inputs-item-name {
                font-size: 14px;
                padding: 10px;
                border-radius: $border-radius;
                border: 2px $modest-color dashed;

                &.active {
                    border-color: rgba($color: $primary-color, $alpha: 0.6);
                }
            }
        }
    }

    .select-input {
        padding: 20px;

        border-radius: $border-radius;
        background-color: $modest-color;

        .title {
            font-weight: 600;
        }

        .items-container {
            li {
                font-size: 14px;
                margin: 10px 0;
                padding: 10px;
                border-radius: $border-radius;
                cursor: pointer;

                &.active {
                    background-color: $success-color;
                    color: white;
                }
            }
            p {
                margin-top: 20px;
                font-style: oblique;
            }
        }
    }
}
@import "src/styles/variables";

.group-container {
    border-radius: 25px;
    margin: 10px 0;

    .group-info-container {
        cursor: pointer;
        display: grid;
        grid-template-columns: 10fr 1fr 0fr;
        align-items: center;
        padding: 14px;

        .left-content {
            display: flex;
            flex-direction: column;

            h3 {
                display: flex;
                align-items: center;
                font-weight: 400;

                svg {
                    margin-right: 10px;
                }
            }
        }

        .settings-container {
            display: flex;
            justify-content: center;
        }
    }

    .events-container {
        .event-card-item h2 {
            padding-left: 40px;
        }
    }

    &.open {
        .group-info-container {
            border-bottom: 2px solid $modest-color;
        }

        .events-container {
            >* {
                &:last-child {
                    .eventCard-container {
                        border: none;
                    }
                }
            }
        }
    }
}
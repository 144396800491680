.reject-modal-container {
    width: 30vw;

    .reject-modal-title {
        margin-bottom: 10px;
    }

    .reject-modal-buttons_container {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        >*:first-child {
            margin-right: 5px;
        }
    }
}
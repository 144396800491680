@import "src/styles/variables";

.myEvents-container {
    .events-switcher {
        display: flex;
        align-items: center;

        >* {
            margin: 0 10px;
        }

        h1 {
            cursor: pointer;
            font-size: 13px;
            transition: all 0.3s cubic-bezier(0.15, 0.84, 0.21, 0.96);

            &.active {
                font-size: 2em;
            }
        }
    }

    .filters-container {
        margin: 10px 0;
    }

    .events-table {
        background-color: $base-color;
        border-radius: $border-radius;
        padding: 32px;
        padding-top: 16px;
        height: 100%;
    }

    .page-head {
        margin-bottom: 15px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            margin: 0;
        }

        .gropus-controls {
            display: flex;

            >*:nth-child(2) {
                margin: 0 10px;
            }

            >*:last-child {
                margin-right: 0;
            }
        }
    }

    .events-table-head {
        display: grid;
        grid-template-columns: 4fr 2fr 2fr 2fr 1fr 120px;
        padding: 14px;
        border-bottom: 2px solid $secondary-color;
        margin-bottom: 10px;

        .event-sort{
            cursor: pointer;
        }

        >* {
            font-size: 18px;
            text-align: center;

            &:first-child {
                text-align: start;
            }
        }
    }

    .events-table-body {
        height: calc(100% - 32px);
        overflow-y: auto;

        &.loaded {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .eventsList {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}
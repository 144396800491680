@import "/src/styles/variables.scss";

.add-in-group-modal-container {
    width: 40vw;
    min-height: 400px;

    display: flex;
    flex-direction: column;

    .group-list-modal-container {
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .group-list-item {
            cursor: pointer;
            width: 100px;
            margin: 10px;
            height: 100px;
            border-radius: $border-radius;
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
}
.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;

    .tooltip-text {
        position: absolute;

        display: none;
        width: fit-content;
        white-space: nowrap;
        background-color: rgba($color: black, $alpha: 0.7);
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        z-index: 1;
    }

    &.top>.tooltip-text {
        bottom: 110%;
        left: 50%;

        margin-left: -60px;
    }

    &.left>.tooltip-text {
        bottom: 50%;
        right: 110%;
    }

    &:hover>.tooltip-text {
        display: block;
    }
}

@import "src/styles/variables";

.eventModal-container {
    position: relative;

    .switch-container {
        margin: auto;
        display: flex;
        align-items: center;

        >* {
            margin: 0 10px;
        }

        h4 {
            cursor: pointer;
            font-size: 1.1rem;
            transition: all 0.3s cubic-bezier(0.15, 0.84, 0.21, 0.96);
            color: $modest-color-font;

            &.active {
                font-size: 1.2rem;
                color: black;
            }
        }
    }
}

.event-modal-loader-container {
    display: flex;
    justify-content: center;
}
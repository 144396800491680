@import "../variables";

.switcher-container {
    width: 40px;
    height: 20px;
    border-radius: 50px;
    border: 1px solid $modest-color; 
    display: flex;
    align-items: center;

    transition: all 0.5s ease-in-out;

    &.active {
        justify-content: flex-end;
    }

    .switch {
        cursor: pointer;
        border-radius: 50%;
        background-color: $primary-color;
        width: 20px;
        height: 20px;
        transform: scale(1.1);
    }
}
@import "src/styles/variables";

.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    padding: 0 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid $modest-color;
    z-index: 40;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navbar-left {
            display: flex;
            align-items: center;

            .logo {
                margin-right: 20px;

                img {
                    width: 250px;
                }
            }

            ul {
                width: 100%;
                display: flex;
                justify-content: space-around;

                li {
                    margin: 0 15px;

                    a {
                        display: inline-block;
                        position: relative;
                        font-weight: 500;
                        font-size: 16px;
                        color: black;

                        &.active,
                        &:hover {
                            color: $success-color;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            display: block;
                            bottom: -5px;
                            left: 0;
                            right: 100%;
                            height: 2px;
                            background-color: $success-color;
                            transition: all 0.3s ease-out;
                            z-index: 10;
                        }

                        &.active::after,
                        &:hover::after {
                            right: 0;
                        }
                    }
                }
            }
        }

        .navbar-right {
            div {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .userInfo {
                padding: 10px 40px;

                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .name{
                    font-size: 18px;
                    font-weight: 500;
                }

                .role{
                    font-size: 16px;
                    font-style: italic;
                }
            }

            .buttons-container{
                button:first-child{
                    margin-right: 10px;
                }
            }
        }
    }
}
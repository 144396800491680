@import "src/styles/variables";

.create-event {
    label {
        margin-bottom: 10px;
        margin-right: 10px;
        font-size: 18px;
        display: inline-block;
    }

    .requaired_star {
        font-size: 20px;
        color: $danger-color;
    }

    .create-event-container {
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 4fr;
        height: 100%;

        >* {
            border-radius: $border-radius;
        }

        .create-event-form-container {
            background-color: white;
            padding: 20px;
        }

        .create-event-switch-container {
            background-color: #f3f3f3;
            margin-right: 20px;
            padding: 10px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            ul {
                li {
                    position: relative;
                    padding: 20px;
                    cursor: pointer;
                    border-radius: $border-radius;

                    &.active {
                        background-color: $success-color;
                        color: white;
                    }
                }
            }
        }
    }
}
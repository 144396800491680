$primary-color: #006CB8;
$secondary-color: #9D9F9E;
$danger-color: #CB3535;
$success-color: #79B833;
$modest-color: #EFEFEF;

$base-color: #FFFFFF;

$modest-color-font: #C1BCBC;
$modest-color-border: #EBEBEB;

$border-radius: 4px;
@import "src/styles/variables";

.eventCard-container {
    border-bottom: 2px solid $modest-color;

    .event-card-item {
        display: grid;
        grid-template-columns: 4fr 2fr 2fr 2fr 1fr 120px;
        align-items: center;
        padding: 14px;

        >* {
            font-size: 16px;
        }

        h2 {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                cursor: pointer;
            }

            label {
                display: inline;
                margin-left: 10px;
                font-size: 18px;
                font-weight: 400;
                cursor: pointer;

                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        h2,
        .event-dates,
        .event-place,
        .event-author {
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
        }

        .event-place,
        .event-dates,
        .event-author {
            text-align: center;
        }

        .event-status {
            display: flex;
            justify-content: center;
        }

        .arrow {
            cursor: pointer;
            transition: all 600ms cubic-bezier(0.15, 0.84, 0.21, 0.96);
        }

        &.open {

            h2,
            .event-dates,
            .event-place,
            .event-author {
                opacity: visible;
                white-space: normal;
            }

            .arrow {
                transform: rotate(180deg);
            }
        }
    }

    .controls-container {
        display: flex;
        justify-content: flex-end;
        padding: 10px;

        >* {
            margin: 0 10px;

            button {
                height: auto;
                padding: 8px 15px;
            }
        }
    }
}
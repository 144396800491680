@import "./variables";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    font-family: "Open Sans Regular", sans-serif;

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: $border-radius;
        background-color: rgba(lightgray, 1);
    }
}

html {
    max-height: 100vh;
}

body {
    background-color: #f6f8fc;
}

main {
    width: 90%;
    margin: auto;
    margin-top: 100px;
    padding-bottom: 50px;

    height: calc(100vh - 150px);

    &.loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h1 {
        margin-bottom: 20px;
        text-align: left;
        font-family: 'Jura', sans-serif;
    }

    .addContent {
        color: $primary-color;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
@import "/src/styles/variables.scss";

.stepOne-container {
    >div {
        margin-bottom: 15px;
        position: relative;
    }

    .dates-container,
    .number-select-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        >* {
            &:first-child {
                margin-right: 5px;
            }

            &:last-child {
                margin-left: 5px;
            }
        }
    }

    .dates-container {
        label {
            font-size: 14px;
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
}
@import "../../../styles/variables.scss";

.createReport-container {
    
    .dates-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        label {
            font-size: 14px;
        }

        >* {
            &:first-child {
                margin-right: 5px;
            }

            &:last-child {
                margin-left: 5px;
            }
        }
    }

    .createReport-input-container {
        label {
            margin: 5px 0;
            display: inline-block;
        }
    }

    div {
        .label-organizers {
            margin-top: 20px;
        }
    }

    .create-organizers-input-container {
        label {
            font-size: 14px;
            margin: 5px 0;
            display: inline-block;
        }

        #invalid-area {
            border: 1px solid $danger-color;
        }
    }

    .inputs-container {
        padding-top: 10px;
    }

    .label-organizers {
        margin: 5px 0;
        display: inline-block;

    }

    .create-organizers-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .inputs-area {
            margin-right: 15px;
        }

    }

    .AllOrganizers {
        margin-top: 25px;
        padding: 8px 10px;
        border: 2px solid $modest-color;
        border-radius: $border-radius;
        max-height: 350px;
        overflow: auto;
    }

    .organizers-container {
        margin-right: 0px;

        >*:first-child {
            margin-bottom: 10px;
        }
    }

    .report-button-container {
        margin-top: 10px;
        padding: 10px 15px 0px 0px;
        display: flex;
        justify-content: flex-end;

        >* {
            margin: 0 10px;
        }
    }
}
@import "../variables";

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 55;
        cursor: pointer;
    }

    .modal-content {
        max-height: 90vh;
        max-width: 70vw;
        z-index: 60;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-radius: $border-radius;
        background: white;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);

        .modal-control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 15px;

            .modal-title {
                .title {
                    font-weight: 500;
                    font-size: 20px;
                    z-index: 10;
                }

                .subtitle {
                    margin-top: 6px;
                    font-size: 14px;
                    color: $modest-color-font;
                }
            }

            .close {
                font-size: 30px;
                z-index: 15;
                cursor: pointer;
            }
        }

        .modal-body {
            width: 100%;

            @media screen and (max-width: 1000px) {
                max-width: 100vw;
                max-height: calc(100vh - 120px);
            }
        }
    }
}

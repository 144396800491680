@import "src/styles/variables";

.authorCard-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 30px;
  position: relative;
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 20px 20px 15px 20px;
  border-bottom: 2px solid $modest-color;
  cursor: default;

  >* {
    display: flex;
    align-items: center;
  }

  h3 {
    text-overflow: ellipsis;
    text-align: start;
    font-weight: 400;
  }

  .contact-info {
    font-weight: 500;
    justify-content: center;
  }

  &:hover {
    color: $primary-color;
    transition: 0.5s color;
  }

  .icon-delete {
    cursor: pointer;
  }
}
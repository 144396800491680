@import "src/styles/variables";

.event-info-container {
    .description {
        border-bottom: 1px solid $modest-color;
        padding-bottom: 5px;

        h5 {
            margin-bottom: 5px;
        }

        p {
            font-size: 90%;
        }
    }

    .comment {
        border-bottom: 1px solid $modest-color;
        padding-bottom: 5px;

        h5 {
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 16px;
            color: $danger-color;
        }

        p {
            font-size: 90%;
        }
    }

    padding: 20px 10px;

    .info {
        div {
            margin-bottom: 5px;
            padding: 5px 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid $modest-color;

            p {
                margin-left: 30px;
                white-space: normal;
                width: 220px;

                &::first-letter {
                    text-transform: uppercase;
                }


            }
        }
    }

    .buttons-container {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        >* {
            margin: 0 10px;
        }
    }
}